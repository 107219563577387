import React, { useState } from 'react';
import './ZenroProfNavagatorBar.css';
import CalendarIcon from '../ZenroProfIcon/CalendarIcon.png'
import CareSummaryIcon from '../ZenroProfIcon/CareIcon.png'
import OverviewIcon from '../ZenroProfIcon/OverViewIcon.png'
import TestsIcon from '../ZenroProfIcon/TestsIcon.png'
import RecommendationsIcon from '../ZenroProfIcon/RecommendationsIcon.png'
import { useLocation, useNavigate } from 'react-router-dom';

const ZenroProfNavagatorBar = ({ onMenuItemSelect, userInfo }) => {
    const [summaryExpend, setSummaryExpend] = useState(true);
    const [historyExpanded, setHistoryExpanded] = useState(true);
    const navigate = useNavigate();
    const toggleMenu = (type) => {
        if (type == 'history') {
            setHistoryExpanded(!historyExpanded)
        } else {
            setSummaryExpend(!summaryExpend)
        }
    };

    const handleMenuItemClick = (menuItem) => {
        onMenuItemSelect(menuItem);
    };

    const handleLogoutClick = (menuItem) => {
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/');
    };



    return (
        <div className="ZenroProfNavagatorBar">
            <button className='ZenroProfNavagatorBar-summary-btn' onClick={() => handleMenuItemClick('patients')}>OVERVIEW
                <i className="fa fa-caret-down"></i>
            </button>
            {/* <button className='ZenroProfNavagatorBar-summary-btn' onClick={() => toggleMenu('summary')}>Summary
                <i className="fa fa-caret-down"></i>
            </button> */}
            {/* {summaryExpend && (
                <ul className="ZenroProfNavagatorBar-summary-menu">

                    <li className='ZenroProfNavagatorBar-li'>
                        <img src={CalendarIcon} alt="shot1" className="ZenroProfNavagatorBar-CalendarIcon" />
                        Recent Results
                    </li>
                    <li className='ZenroProfNavagatorBar-li'>
                        <img src={CareSummaryIcon} alt="shot1" className="ZenroProfNavagatorBar-CareSummaryIcon" />
                        Care Summary
                    </li>
                </ul>
            )}
            <button className='ZenroProfNavagatorBar-history-btn' onClick={() => handleMenuItemClick('patients')}>PATIENTS
                <i className="fa fa-caret-down"></i>
            </button> */}
            {/* <button className='ZenroProfNavagatorBar-history-btn' onClick={() => toggleMenu('history')}>History
                <i className="fa fa-caret-down"></i>
            </button> */}
            {historyExpanded && (
                <ul className="ZenroProfNavagatorBar-history-menu">
                    {/* Your menu items go here */}
                    <li className='ZenroProfNavagatorBar-li'>
                        <img src={OverviewIcon} alt="shot1" className="ZenroProfNavagatorBar-OverviewIcon" />
                        overview
                    </li>
                    <li className='ZenroProfNavagatorBar-li'>
                        <img src={TestsIcon} alt="shot1" className="ZenroProfNavagatorBar-TestsIcon" />
                        Tests
                    </li>
                    <li className='ZenroProfNavagatorBar-li'>
                        <img src={RecommendationsIcon} alt="shot1" className="ZenroProfNavagatorBar-RecommendationsIcon" />
                        Recommendations
                    </li>
                </ul>
            )}
            <button className='ZenroProfNavagatorBar-message-btn' onClick={() => handleMenuItemClick('message')}>MESSAGES
                <i className="fa fa-caret-down"></i>
            </button>
            {/* <button className='ZenroProfNavagatorBar-history-btn' onClick={() => toggleMenu('history')}>History
                <i className="fa fa-caret-down"></i>
            </button> */}
            {historyExpanded && (
                <ul className="ZenroProfNavagatorBar-message-menu">
                    {/* Your menu items go here */}
                    <li className='ZenroProfNavagatorBar-li'>
                        <img src={OverviewIcon} alt="shot1" className="ZenroProfNavagatorBar-OverviewIcon" />
                        inbox
                    </li>
                </ul>
            )}
            <button className='ZenroProfNavagatorBar-logout-btn' onClick={() => handleLogoutClick()}>LOG OUT
                <i className="fa fa-caret-down"></i>
            </button>
        </div >
    );
};

export default ZenroProfNavagatorBar;
