import React from 'react';
import './PatientsBox.css';

const PatientsBox = ({ patients, selectedPatient, onPatientSelect }) => {
    const handleItemClick = (patient) => {
        if (selectedPatient !== patient) { // Only update if a different test is clicked
            onPatientSelect(patient);
            console.log(selectedPatient)
        }
    };
    console.log(patients)
    return (
        <div className="PatientsBox">
            <h1 className='PatientsBox-h1'>Overview</h1>
            <h1 className='PatientsBox-userlist-text'>USER LIST</h1>
            <div className='PatientsBox-filter-box'>
                <input type="text" className="PatientsBox-search" placeholder="Search.."/>
            </div>
            <div className='PatientsBox-letter-box'>
                <h1 className='PatientsBox-h1-letters'>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z</h1>
            </div>
            {/* labels for patients */}
            <div className='PatientsBox-labels'>
                <h2 className='PatientsBox-h2 Name'>Name</h2>
                <h2 className='PatientsBox-h2 Email'>Email</h2>
                <h2 className='PatientsBox-h2 Phone'>Phone</h2>
            </div>
            {/* list of patients */}
            <div className="PatientsBox-item-container">
                <nav className="PatientsBox-items">
                    <ul className='PatientsBox-ul'>
                        {patients.slice().reverse().map(patient => (
                            <li
                                key={patient.PatientID}
                                className={`patient-item ${selectedPatient === patient ? 'active' : ''}`}
                                onClick={() => handleItemClick(patient)}
                            >
                                <span className="PatientsBox-patient-name">{patient.PatFirstName + " " +patient.PatLastName}</span>
                                <span className="PatientsBox-patient-email">{patient.PatEmail}</span>
                                <span className="PatientsBox-patient-phone">{patient.PatPhone}</span>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default PatientsBox;
