import React from 'react';
import './ProductsPage.css';
import { LinearGradient } from 'react-text-gradients'

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

import zenroPromoImage from '../assets/pimasensScreenshots/zenro_phone_cropped.png';
import agriloPromoImage from '../assets/pimaspecScreenshots/agrilo_phone_cropped.png';

const ProductsPage = () => {
    return (
        <div className="Products">
            <title>Pimasens | Products</title>
            <meta name="Pimasens" content="Health, feedback, mental health, agriculture, farming" />
            <meta name="keywords" content="Explore the fleet of Pimasens products" />
            <NavBar />
            <div className="Products-container">
                <div className="Products-box">
                    <h2>
                        <LinearGradient gradient={['to left', '#7EA6FF ,#F67F8D']} className="Products-header">
                            Zenro
                        </LinearGradient>
                    </h2>
                    <span className="Products-short-description">Enhance your well-being with comprehensive horomone tracking and mental health support</span>
                    <span className="Button-span">
                        <button className="Explore-Button" onClick={null}>EXPLORE</button>
                        <button className="Get-started-button" onClick={null}>GET STARTED</button>
                    </span>
                    <img className="Zenro-promo-img" src={zenroPromoImage} alt="Zenro app displaying main splash" />
                </div>

                <div className="Products-box">
                    <h2>
                        <LinearGradient gradient={['to left', '#F6F64A ,#32E237']} className="Products-header">
                            Agrilo
                        </LinearGradient>
                    </h2>
                    <span className="Products-short-description">Optimize your farming efficiency with advanced soil, water, and crop analysis.</span>
                    <span className="Button-span">
                        <button className="Explore-Button" onClick={null}>EXPLORE</button>
                        <button className="Get-started-button" onClick={null}>GET STARTED</button>
                    </span>
                    <img className="Zenro-promo-img" src={agriloPromoImage} alt="Zenro app displaying main splash" />
                </div>
            </div>
        </div>
    );
};

export default ProductsPage;