import React, { useState, useEffect } from 'react';
import './DisplayMessageBox.css';

const DisplayMessageBox = ({ mail }) => {

    return (
        <div className="DisplayMessageBox">
            {mail && (
                <div>
                    <div className='DisplayMessageBox-spotlight'>
                        <h1 className='DisplayMessageBox-label'>Mail</h1>
                    </div>
                    <h1 className='DisplayMessageBox-label'>{"Subject: " + mail.ToSubject}</h1>
                        <h1 className='DisplayMessageBox-label'>{"Date: "+ Date(mail.Time).toLocaleString()}</h1>
                        <h1 className='DisplayMessageBox-label'>{"From: "+mail.FromFirstName+" "+mail.FromLastName}</h1>
                        <h1 className='DisplayMessageBox-label'>{"To: "+mail.ToFirstName+" "+mail.ToLastName}</h1>
                    <div className="DisplayMessageBox-message-box">
                        <h1 className='DisplayMessageBox-h1'>{mail.ToMessage}</h1>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DisplayMessageBox;