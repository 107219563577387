import React from "react";
import "./PlanCard.css";

const PlanCard = ({ planType, planTitle, price, description, bgColor, priceColor, buttonText, bottomNavText }) => {
    return (
        <div className="plan-card" style={{ background: bgColor }}>
            <div className="plan-content">
                <div className="plan-header">{planType.toUpperCase()} PLAN</div>
                <div className="plan-price">
                    <span className="price-symbol" style={{ color: priceColor }} >$</span>
                    <span className="price-value" style={{ color: priceColor }}>{price}</span>
                </div>
                <div className="plan-title">{planTitle.toUpperCase()}</div>
                <div className="plan-description">{description}</div>
            </div>
            <div className="plan-footer">
                <button className="signup-button">{buttonText}</button>
                <div className="switch-plan">{bottomNavText} &gt;</div>
            </div>
        </div>
    );
};

export default PlanCard;