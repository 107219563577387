import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Zenro.css';
import axios from 'axios';
import OverviewPage from './OverviewPage';
import HistoryPage from './HistoryPage';
import RecommendationPage from './RecommendationsPage';
import AccountEditPage from './AccountEditPage';
import ZenroNavagatorBar from '../components/ZenroNavagatorBar';
import MessagingPage from './MessagingPage';

const Zenro = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
    const location = useLocation();
    const [User, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState(null);
    const navigate = useNavigate();
    //const access = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMwOTE5NzUxLCJpYXQiOjE3MjgzMjc3NTEsImp0aSI6IjhiZjIwYjcyZTMyNDQyNDM4MDA2YmY0M2Y2NmMxYzI1IiwiVUlEIjo2fQ.nyBEE9KaQNZTukFxULVhyxVxHmazJP3HpFBpTSFyKOY'

    useEffect(() => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('access=')) {
                // Extract the cookie value
                var accessToken = cookie.substring('access='.length);
                setAccess(accessToken);
            }
        }
        if (!accessToken) {
            navigate('/login');
        } else {
            axios.get('https://www.pimasens.com/api/getUser/', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error === "No tests found") {
                        setError('An error occurred');
                        navigate('/login');
                    } else {
                        document.cookie = "Username="+data.Username+";path=/";
                        if (data.UserType == "ZenroProf"){
                            navigate('/zenroProf');
                        }
                        setUser(data);
                    }
                    setLoading(false); // Set loading to false after data is fetched
                })
                .catch(error => {
                    setError('An error occurred');
                    setLoading(false); // Set loading to false even if there's an error
                });
        }
    }, [access, navigate]);


    return (


        <div className="Zenro">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    <div className="content">
                        {selectedMenuItem === 'overview' && <OverviewPage access={access} />}
                        {selectedMenuItem === 'history' && <HistoryPage access={access} />}
                        {selectedMenuItem === 'recommendation' && <RecommendationPage access={access} />}
                        {selectedMenuItem === 'messages' && <MessagingPage access={access}/>}
                        {selectedMenuItem === 'account' && <AccountEditPage access={access} userInfo={User} />}
                    </div>
                    <ZenroNavagatorBar onMenuItemSelect={setSelectedMenuItem} userInfo={User} />
                </>
            )}
        </div>
    );

};

export default Zenro;