import React, { useState, useEffect } from 'react';
import './RecentTestCircle.css';

const RecentTestCircle = ({ testData }) => {
  const CircleColor = ['#f54244', '#fce93d', '#47a857', '#fce93d', '#f54244']
  const CircleRotation = ['180deg', '225deg', '315deg', '45deg', '90deg']
  const CircleHighlight = ['#f54244', '#fce93d', '#47a857', '#fce93d', '#f54244']
  const HighlightCords = ['-80px 37px', '-81px 34px', '-79px 38px', '-76px 37px', '-76px 37px']
  const HighlightRotation = ['137deg', '180deg', '270deg', '0deg', '45deg']
  const HighlightTriangleCords = ['-92px 6px', '-92px 9px', '-90px 10px', '-85px 9px', '-85px 9px']
  const HighlightTriangleRotation = ['137deg', '180deg', '270deg', '0deg', '45deg']
  const HighlightDialCords = ['-104px 29px', '-104px 32px', '-102px 33px', '-100px 32px', '-100px 32px']
  const HighlightDialRotation = ['137deg', '180deg', '271deg', '0deg', '45deg']
  const HighlightDialOrder = [` scaleY(-1)`, ` scaleY(-1)`, ``, ``, ``]
  const HighlightDialColorOffset1 = ['offset="0', 'offset=0.41']
  const HighlightDialColorOffset2 = ['offset="0.41', 'offset=0.41']
  const HighlightDialColorOffset3 = ['offset="0.77', 'offset=0.41']
  const HighlightDialColorStopOpacity1 = ['0.05', '0.7']
  const HighlightDialColorStopOpacity2 = ['0.57', '0.5']
  const HighlightDialColorStopOpacity3 = ['0.9', '0.7']
  const [option, setOption] = useState(0);
  useEffect(() => {
    if (testData.TestSeverity == 3) {
      setOption(1)
    }
  }, []);
  const circleWheelColor = {
    stopColor: testData && CircleColor[testData.TestSeverity - 1]
  }

  const circleWheelRotation = {
    transform: `rotate(${testData && CircleRotation[testData.TestSeverity - 1]})`,
  };

  const highlight = {
    transform: `rotate(${testData && HighlightRotation[testData.TestSeverity - 1]}) scaleY(-1)`,
    transformOrigin: testData && HighlightCords[testData.TestSeverity - 1]
  }

  const highlightArrow = {
    transform: `rotate(${testData && HighlightTriangleRotation[testData.TestSeverity - 1]}) scaleY(-1)`,
    transformOrigin: testData && HighlightTriangleCords[testData.TestSeverity - 1]
  }

  const highlightDialCord = {
    transform: `rotate(${testData && HighlightDialRotation[testData.TestSeverity - 1]})` + HighlightDialOrder[testData.TestSeverity - 1],
    transformOrigin: testData && HighlightDialCords[testData.TestSeverity - 1]
  }
  const highlightDialColor1 = {
    stopColor: testData && CircleColor[testData.TestSeverity - 1],
    offset: testData && HighlightDialColorOffset1[option],
    stopOpacity: testData && HighlightDialColorStopOpacity1[option]

  }

  const highlightDialColor2 = {
    stopColor: testData && CircleColor[testData.TestSeverity - 1],
    offset: testData && HighlightDialColorOffset2[option],
    stopOpacity: testData && HighlightDialColorStopOpacity2[option]
  }

  const highlightDialColor3 = {
    stopColor: testData && CircleColor[testData.TestSeverity - 1],
    offset: testData && HighlightDialColorOffset3[option],
    stopOpacity: testData && HighlightDialColorStopOpacity3[option]
  }

  return (
    <div className='RecentTestCircle'>
      {/* sverity 1*/}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56" fill="none" className="dial1">
        <path d="M46.8486 46.3827C39.3857 41.753 32.4463 36.3301 26.1521 30.2081C20.0301 23.9121 14.6056 16.9744 9.97755 9.51147"
          stroke="#2C2C2E"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial1-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" className='dial1-circle'>
        <circle cx="5.50568" cy="5.50568" r="4.34659" strokeWidth="2.31818" transform="matrix(-1 0 0 1 11.9297 0.609131)" />
      </svg>
      {/* sverity 2 */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56" fill="none" className="dial2">
        <path d="M46.8486 46.3827C39.3857 41.753 32.4463 36.3301 26.1521 30.2081C20.0301 23.9121 14.6056 16.9744 9.97755 9.51147"
          stroke="#2C2C2E"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial2-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" className='dial2-circle'>
        <circle cx="5.50568" cy="5.50568" r="4.34659" strokeWidth="2.31818" transform="matrix(-1 0 0 1 11.9297 0.609131)" />
      </svg>
      {/* severity 3-1 */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56" fill="none" className="dial3-1">
        <path d="M46.8486 46.3827C39.3857 41.753 32.4463 36.3301 26.1521 30.2081C20.0301 23.9121 14.6056 16.9744 9.97755 9.51147"
          stroke="#2C2C2E"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial3-1-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" className='dial3-1-circle'>
        <circle cx="5.50568" cy="5.50568" r="4.34659" strokeWidth="2.31818" transform="matrix(-1 0 0 1 11.9297 0.609131)" />
      </svg>
      {/* severity 3-2 */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56" fill="none" className="dial3-2">
        <path d="M46.8486 46.3827C39.3857 41.753 32.4463 36.3301 26.1521 30.2081C20.0301 23.9121 14.6056 16.9744 9.97755 9.51147"
          stroke="#2C2C2E"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial3-2-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      {/* severity 3-3 */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56" fill="none" className="dial3-3">
        <path d="M46.8486 46.3827C39.3857 41.753 32.4463 36.3301 26.1521 30.2081C20.0301 23.9121 14.6056 16.9744 9.97755 9.51147"
          stroke="#2C2C2E"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial3-3-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" className='dial3-3-circle'>
        <circle cx="5.50568" cy="5.50568" r="4.34659" strokeWidth="2.31818" transform="matrix(-1 0 0 1 11.9297 0.609131)" />
      </svg>
      {/* severity 4 */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56" fill="none" className="dial4">
        <path d="M46.8486 46.3827C39.3857 41.753 32.4463 36.3301 26.1521 30.2081C20.0301 23.9121 14.6056 16.9744 9.97755 9.51147"
          stroke="#2C2C2E"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial4-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" className='dial4-circle'>
        <circle cx="5.50568" cy="5.50568" r="4.34659" strokeWidth="2.31818" transform="matrix(-1 0 0 1 11.9297 0.609131)" />
      </svg>
      {/* severity 5 */}
      <svg height="57" width="57" fill="none" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" className='dial5'>
        <path d="M10.2627 46.7123C17.7256 42.0826 24.665 36.6597 30.9592 30.5377C37.0812 24.2417 42.5058 17.304 47.1338 9.84106" stroke="#2C2C2E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="18.6551" />
      </svg>
      <svg fill="none" viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" className='dial5-line'>
        <path d="M1.98658 1.65845C6.13298 8.49332 10.9931 14.8472 16.478 20.6132C21.5728 25.6788 27.1395 30.2217 33.0977 34.1785" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg fill="none" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" className='dial5-circle'>
        <circle cx="5.50568" cy="5.50568" r="4.34659" strokeWidth="2.31818" transform="matrix(-1 0 0 1 11.9297 0.609131)" />
      </svg>

      {/* severity circle */}
      <svg height="164" width="163" fill="none" viewBox="0 0 163 164" xmlns="http://www.w3.org/2000/svg" className='severity-circle' style={circleWheelRotation}>
        <path d="M79.3015 154.732C119.466 156.009 153.061 124.485 154.339 84.3202C155.616 44.1556 124.091 10.5604 83.9269 9.28311C43.7623 8.00584 10.1671 39.5302 8.88984 79.6948" stroke="url(#paint0_radial_213_3624)" strokeLinecap="round" strokeOpacity="0.75" strokeWidth="16.9694" />
        <defs>
          <radialGradient id="paint0_radial_213_3624" cx="0" cy="0" gradientTransform="translate(37.7352 129.144) rotate(-44.5955) scale(136.758 161.353)" gradientUnits="userSpaceOnUse" r="1">
            <stop className='severity-circle-color' stopOpacity="0" style={circleWheelColor} />
            <stop className='severity-circle-color' offset="0.5" stopColor="#34A851" stopOpacity="0" style={circleWheelColor} />
            <stop className='severity-circle-color' offset="1" stopColor="#34A851" style={circleWheelColor} />
          </radialGradient>
        </defs>
      </svg>
      <svg fill="none" viewBox="0 0 29 78" xmlns="http://www.w3.org/2000/svg" className='severity-circle-highlight' style={highlight}>
        <path d="M23.1635 10.5047C21.6805 4.7298 15.7968 1.25049 10.0219 2.73348C4.24691 4.21647 0.767592 10.1002 2.25058 15.8751C4.22785 23.5749 5.36879 31.4674 5.65842 39.4168C5.70483 47.3671 4.89539 55.3008 3.24454 63.0814C2.00703 68.9139 5.73199 74.6452 11.5645 75.8827C17.3969 77.1202 23.1283 73.3953 24.3658 67.5628C26.3502 58.2102 27.3171 48.6708 27.2484 39.1111C27.2476 39.0107 27.2455 38.9105 27.242 38.8102C26.9069 29.2579 25.5422 19.7675 23.1635 10.5047Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.93636" />
      </svg>
      <svg fill="none" viewBox="0 0 11 20" xmlns="http://www.w3.org/2000/svg" className='severity-circle-highlight-triangle' style={highlightArrow}>
        <path d="M0.848567 10.6546C0.378508 10.1796 0.378508 9.41477 0.848567 8.93984L8.88407 0.821064C9.65003 0.047167 10.9691 0.589566 10.9691 1.67842L10.9691 17.916C10.9691 19.0048 9.65003 19.5472 8.88407 18.7733L0.848567 10.6546Z" fill="white" />
      </svg>
      <svg height="64" width="15" fill="none" viewBox="0 0 15 64" xmlns="http://www.w3.org/2000/svg" className='severity-circle-highlight-dial' style={highlightDialCord}>
        <path d="M6.07192 58.1437C8.07525 49.5929 9.14762 40.8514 9.26935 32.0718C9.14635 23.291 8.07639 14.5496 6.07179 5.99998" stroke="url(#paint0_linear_213_4577)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="11.0114" />
        <defs>
          <linearGradient id="paint0_linear_213_4577" gradientUnits="userSpaceOnUse" x1="5.95404" x2="5.95404" y1="58.0258" y2="6.11773">
            <stop stopColor="#FFEB03" stopOpacity="0.05" style={highlightDialColor1} />
            <stop offset="0.41" stopColor="#FFEB03" stopOpacity="0.57" style={highlightDialColor2} />
            <stop offset="0.77" stopColor="#FFEB03" stopOpacity="0.9" style={highlightDialColor3} />
          </linearGradient>
        </defs>
      </svg>
      <h1 className='severity-circle-h1'>Cortisol</h1>
    </div>
  );
};

export default RecentTestCircle;
