import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import SuccessPage from './pages/SuccessPage';
import ContactPage from './pages/ContactPage';
import Agrilo from './agrilo_pages/Agrilo';
import Zenro from './zenro_pages/Zenro';
import AboutUsPage from './pages/AboutUsPage';
import ProductsPage from './pages/ProductsPage';
import PricingPage from './pages/PricingPage';
import ComingSoonPage from './pages/ComingSoonPage';
import ResetEmailPage from './pages/ResetEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ActivateAccountPage from './pages/ActivateAccountPage';
import ActivateTokenPage from './pages/ActivateTokenPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiePolicyPage from './pages/CookiePolicyPage'
import ZenroProf from './zenro_prof_pages/ZenroProf';


const App = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/home':
        document.title = 'Home - Pimasens';
        break;
      case '/login':
        document.title = 'Login - Pimasens';
        break;
      case '/sign-up':
        document.title = 'Sign Up - Pimasens';
        break;
      case '/success':
            document.title = 'Success - Pimasens';
        break;
      case '/contact-us':
        document.title = 'Contact Us - Pimasens';
        break;
        case '/pricing':
            document.title = 'Pricing - Pimasens';
            break;
        case '/products':
            document.title = 'Products - Pimasens';
            break;
        case '/about-us':
            document.title = 'About Us - Pimasens';
            break;
      default:
        document.title = 'pimasens';
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/products" element={<ProductsPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/success" element={<ComingSoonPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/Zenro" element={<Zenro/>} />
      <Route path="/send" element={<ResetEmailPage />} />
      <Route path="/reset/:token" element={<ResetPasswordPage />} />
      <Route path="/activate/:token" element={<ActivateAccountPage/>}/>
      <Route path="/authenticationCode" element={<ActivateTokenPage/>}/>
      <Route path="about/about-us" element={<ComingSoonPage/>}/>
      <Route path="about/privacy-policy" element={<PrivacyPolicyPage/>}/>
      <Route path="/about/cookie-policy" element={<CookiePolicyPage/>}/>
      <Route path="/Agrilo" element={<Agrilo/>}/>
      <Route path="/ZenroProf" element={<ZenroProf/>}/>
    </Routes>
  );
};

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
