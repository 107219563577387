import React, { useState, useEffect } from 'react';
import './PatientBar.css';
import axios from 'axios';
const PatientBar = ({ patient, selectOverview, access }) => {
    const [resetNotes, setResetNotes] = useState(true);
    const [notes, setNotes] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [error, setError] = useState('');
    const onOverviewClick = () => {
        selectOverview(true)
    };

    useEffect(() => {
        if(patient==null){
            setNotes("")
            setResetNotes(true)
        }else{
            axios.get('https://www.pimasens.com/api/getDoctorNotes/?DoctorAssignmentID='+patient.DoctorAssignmentID, {
                headers: {
                    'Authorization': 'Bearer ' + access.access
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error === "No tests found") {
                        setError('An error occurred');
                    } else {
                        setNotes(data.Comments);
                    }
                    setLoading1(false);
                })
                .catch(error => {
                    setError('An error occurred');
                    setLoading1(false);
                });
        }
    }, [patient]);

    const handleSaveClick = () => {
        let dataToSend = { Note: notes, DoctorAssignmentID: patient.DoctorAssignmentID};

        axios.put('https://www.pimasens.com/api/updateDoctorNote/', dataToSend, {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }
    return (
        <div className="PatientBar">
            {(patient &&
                <div>
                    <div className='PatientBar-spotlight'>
                        <h1 className='PatientBar-text'>USER SPOTLIGHT</h1>
                        <button className='PatientBar-overview-btn' onClick={() => onOverviewClick()}>Overview</button>
                    </div>
                    <div className='PatientBar-spotlight-box'>
                        <h1 className='PatientBar-h1'>{"Name: "+patient.PatFirstName + " " +patient.PatLastName}</h1>
                        <h1 className='PatientBar-h1'>{"Phone#: "+patient.PatPhone}</h1>
                        <h1 className='PatientBar-h1'>{"Email: "+patient.PatEmail}</h1>
                    </div>
                    <div className="PatientBar-medical-history">
                        <h1 className='PatientBar-text'>Patient Notes</h1>
                    </div>
                    <textarea placeholder="Enter note here" className='PatientBar-notes-text-box' onChange={(e) => setNotes(e.target.value)} value={notes}></textarea>
                    <button className='PatientBar-save-note-button' onClick={() => handleSaveClick()}>Save</button>
                </div>
            )}
        </div>
    );
};

export default PatientBar;