import React from 'react';
import { Helmet } from "react-helmet";
import { LinearGradient } from 'react-text-gradients'
import './PricingPage.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';
import PlanCard from "../components/PlanCard";
import "../components/PlanCard.css";


const PricingPage = () => {
    const zenroPlans = [
        {
            planType: "Monthly",
            planTitle: "Basic",
            price: 0,
            description: "Access to all basic features and limited data storage.",
            bgColor: "linear-gradient(180deg, #1E1E20, #7E7E86)", // Dark gray gradient
            buttonText: "Signup",
            bottomNavText: "Switch to yearly plan",
        },
        {
            planType: "Monthly",
            planTitle: "Standard",
            price: 6,
            description: "Access to all features, including Doctor and Specialist Portals, and standard data storage.",
            bgColor: "linear-gradient(180deg, #4E5E80, #4D6DFD)", // Blue gradient
            buttonText: "Start a Free Trial",
            bottomNavText: "Switch to yearly plan",
            priceColor: "#C0C0F4",
        },
        {
            planType: "Yearly",
            planTitle: "Premium",
            price: 39,
            description: "Access to all features, unlimited data storage, priority support, and advanced analytics.l premium features and unlimited data storage.",
            bgColor: "linear-gradient(180deg, #1E1E20, #7E7E86)", // Orange gradient
            buttonText: "Start a free trial",
            bottomNavText: "Switch to monthly plan",
        },
    ];

    const agriloPlans = [
        {
            planType: "Monthly",
            planTitle: "Basic",
            price: 0,
            description: "Access to all basic features and limited data storage.",
            bgColor: "linear-gradient(180deg, #1E1E20, #7E7E86)", // Dark gray gradient
            buttonText: "Signup",
            bottomNavText: "Switch to yearly plan",
        },
        {
            planType: "Monthly",
            planTitle: "Standard",
            price: 6,
            description: "Access to all features, including Doctor and Specialist Portals, and standard data storage.",
            bgColor: "linear-gradient(180deg, #C5A5FB, #4D6DFD)", // Blue gradient
            buttonText: "Start a Free Trial",
            bottomNavText: "Switch to yearly plan",
            priceColor: "#4C31FC",
        },
        {
            planType: "Yearly",
            planTitle: "Premium",
            price: 49,
            description: "Access to all features, unlimited data storage, priority support, and advanced analytics.",
            bgColor: "linear-gradient(180deg, #1E1E20, #7E7E86)", // Orange gradient
            buttonText: "Start a free trial",
            bottomNavText: "Switch to monthly plan",
        },
    ];

    return (
        <main className="Pricing">
            <Helmet>
                <meta name="description" content="Explore the pricing options for the fleet of Pimasens Products." />
                <meta name="keywords" content="Mental Health, Agriculture, Therapy, Performance, Mobile App" />
                <meta name="author" content="Pimasens" />
                <meta property="og:title" content="Pricing - Pimasens" />
                <meta property="og:description" content="Explore the pricing options for the fleet of Pimasens Products." />
                <meta property="og:url" content="https://www.pimasens.com/pricing" />
                <meta property="og:image" content="https://www.pimasens.com/static/media/BlueBackLogo.bd7ab20d1a77bda987c2.png" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <NavBar />
            <div className="Pricing-Content">
                {/* Zenro */}
                <div className="pricing-container">
                    {/* Header Section */}
                    <div className="pricing-header">
                        <h2>
                            <LinearGradient gradient={['to left', '#7EA6FF ,#F67F8D']} className="Zenro-Title">
                            Zenro
                            </LinearGradient>
                        </h2>
                        <h2 >Pricing</h2>
                    </div>

                    <hr id="pricing-linebreak" />

                    {/* Main Content */}
                    <div className="pricing-content">
                        {/* Left Section */}
                        <div className="pricing-info">
                            <h1>Enhance Your Well-Being with <span>every plan.</span></h1>
                            <p>Try free for 14 days. No credit card required.</p>
                        </div>

                        {zenroPlans.map((plan, index) => (
                            <PlanCard
                                key={index}
                                planType={plan.planType}
                                planTitle={plan.planTitle}
                                price={plan.price}
                                description={plan.description}
                                bgColor={plan.bgColor}
                                buttonText={plan.buttonText}
                                bottomNavText={plan.bottomNavText}
                                priceColor={plan.priceColor}
                            />
                        ))}
                    </div>

                    {/* Footer */}
                    <div className="footer">
                        <p class="question-text">Are you a <span>professional or specialist?</span></p>
                        <a href="/" class="pricing-link">PimaSens Pro Pricing</a>
                    </div>
                </div>

                {/* Agrilo */}
                <div className="pricing-container">
                    {/* Header Section */}
                    <div className="pricing-header">
                        <h2>
                            <LinearGradient gradient={['to left', '#F6F64A ,#32E237']} className="Zenro-Title">
                                Agrilo
                            </LinearGradient>
                        </h2>
                        <h2 >Pricing</h2>
                    </div>

                    <hr id="pricing-linebreak" />

                    {/* Main Content */}
                    <div className="pricing-content">
                        {/* Left Section */}
                        <div className="pricing-info">
                            <h1>Maximize Your <br/><span>Crop Potential.</span></h1>
                            <p>Try free for 14 days. No credit card required.</p>
                        </div>

                        {agriloPlans.map((plan, index) => (
                            <PlanCard
                                key={index}
                                planType={plan.planType}
                                planTitle={plan.planTitle}
                                price={plan.price}
                                description={plan.description}
                                bgColor={plan.bgColor}
                                buttonText={plan.buttonText}
                                bottomNavText={plan.bottomNavText}
                                priceColor={plan.priceColor}
                            />
                        ))}
                    </div>

                    {/* Footer */}
                    <div className="footer">
                        <p class="question-text">Are you a <span>professional or specialist?</span></p>
                        <a href="/" className="pricing-link">PimaSens Pro Pricing</a>
                    </div>
                </div>
                
            </div>
            <BottomNav />
        </main>

    );
};

export default PricingPage;